import React from "react"
import { graphql } from "gatsby"

// Components
import Seo from "../components/Seo"
import RequestDemo from "../components/RequestDemo"

// Types
import { IDemoPage, IRequestDemoPage } from "../prismic-types/request-demo-page"

interface IProps {
  content: IRequestDemoPage
}

const BookDemoPage: React.FC<IProps> = ({ content }) => {
  const seo = {
    title: content.meta.title,
    description: content.meta.description,
    lang: content.meta.lang,
    datePublished: content.meta.publishedDate,
    dateModified: content.meta.modifiedDate,
    slug: "contact",
  }

  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        dateModified={seo.dateModified}
        datePublished={seo.datePublished}
        lang={seo.lang}
        slug={seo.slug}
        breadCrumbItems={[{ name: "Contact Us", url: seo.slug }]}
      />
      <RequestDemo
        title={content.page.title}
        text={content.page.text}
        phoneTitle={content.page.phoneTitle}
        phoneDescription={content.page.phoneDescription}
        phoneNumber={content.page.phoneNumber}
        labels={content.page.labels}
        variant="contact"
      />
    </>
  )
}

export const query = graphql`
  query bookDemoPage($locale: String!) {
    prismicRequestADemoPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        text {
          html
        }
        phone_title {
          text
        }
        phone_description {
          html
        }
        phone_number
        download_title {
          text
        }
        download_text {
          html
        }
        name
        notes
        phone
        email
        company
        interest
        employees
      }
    }
  }
`

export default ({ data }: any) => {
  const raw = data.prismicRequestADemoPage.data

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: data.prismicRequestADemoPage.first_publication_date,
    modifiedDate: data.prismicRequestADemoPage.last_publication_date,
    lang: data.prismicRequestADemoPage.lang,
  }

  const page: IDemoPage = {
    title: raw.title.text,
    text: raw.text.html,
    downloadTitle: raw.download_title.text,
    downloadText: raw.download_text.html,
    phoneTitle: raw.phone_title.text,
    phoneDescription: raw.phone_description.html,
    phoneNumber: raw.phone_number,
    labels: {
      name: raw.name,
      email: raw.email,
      phone: raw.phone,
      company: raw.company,
      notes: raw.notes,
      employees: raw.employees,
      interest: raw.interest,
    },
  }

  const content = {
    meta,
    page,
  }

  return <BookDemoPage content={content} />
}
